import { useMemo } from 'react';
import { config } from '~/constants';

const { folder, logoName } = config.CONFIG.logo!;

export const Logo: React.FC = () => {

	const src = useMemo<string>(
		() => `${process.env.PUBLIC_URL}/logo/${folder}/${logoName}`,
		[]
	);

	return (
		<div className="app--logo">
			<img src={src} />
		</div>
	);

}
