import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, Link, Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import cn from 'classnames';

import { useConfig, useData, useUser } from '~/store';
import { mediaLink } from '~/utils';
import { ModalRequest, ModalUserEdit } from '~/containers/manage';

import {
	Button,
	Icon,
	Legend,
	Loader,
	OptionView,
	Pages,
	Select,
	SquareIcon,
	useOverlay,
	UserLabel
} from '~/components';

import {
	confirm,
	getActiveServices,
	IRequest,
	isAthlete,
	isBusiness,
	RequestsReadInput,
	RequestType,
	RequestTypeOptions,
	REQUEST_STATUS,
	REQUEST_TYPE,
	_confirm
} from '~/services';

export const User: React.FC = () => {

	const { goBack } = useHistory();

	const { id } = useParams<{ id: string }>();

	const config = useConfig();

	const { util, getSportsByIds } = useData();

	const wrapRef = useRef<HTMLDivElement>(null);

	const [ query, setQuery ] = useQueryParams({
		page: withDefault(NumberParam, 0),
		requestType: StringParam,
		payoutStatus: NumberParam,
	});

	const { Over, OverItem, ...over } = useOverlay({
		request: {
			title: 'Request',
			className: 'request',
		},
		edit: {
			className: 'edit--modal',
		},
	}, async (key) => {

		if (key !== 'edit' || !isUpdated) {
			return true;
		}

		const confirmed = await _confirm.editLeave();

		if (confirmed) {
			setIsUpdated(false);
		}

		return confirmed;

	});

	const [ isUpdated, setIsUpdated ] = useState(false);

	const [ params, setParams ] = useState<Omit<RequestsReadInput, 'user_id'>>({
		page: query.page,
		page_size: 20,
		request_type: query.requestType as RequestType,
		payout_status: query.payoutStatus as 0 | 1 | undefined,
	});

	useEffect(() => {

		wrapRef?.current?.scrollTo({ top: 0, left: 0 });

		setParams((val) => ({
			...val,
			page: (
				query.requestType !== val.request_type ||
				query.payoutStatus !== val.payout_status
			) ? 0 : query.page,
			request_type: query.requestType as RequestType,
			payout_status: query.payoutStatus as 0 | 1,
		}));

	}, [ query ]);

	const {
		user,
		empty,
		loading,
		requests,
		removeUser,
		toggleUserBlock,
		togglePaidStatus,
	} = useUser(id, params);

	const remove = useCallback(
		async (id: string, e: React.MouseEvent) => {

			e.preventDefault();

			if (!await confirm({
				title: 'Irreversible Action',
				message: 'Are you sure want to remove the user?',
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await removeUser(id);

					if (typeof message === 'string') {
						reject(message);
					}

					goBack();

					resolve(true);

				}),
				{
					pending: 'Removing the user',
					success: 'The user has been removed',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ removeUser, goBack ]
	);

	const toggleBlock = useCallback(
		async (id: string, state: boolean, e: React.MouseEvent) => {

			e.preventDefault();

			if (!await confirm({
				title: 'Irreversible Action',
				message: `Are you sure want to ${state ? 'unb' : 'b'}lock the user?`,
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await toggleUserBlock(id);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: `${state ? 'Un' : 'B'}locking the user`,
					success: `The user has been ${state ? 'un' : 'b'}locked`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ toggleUserBlock ]
	);

	const _togglePaidStatus = useCallback(
		async (id: string, user_id: string, amount: number, status: boolean) => {

			if (!await confirm({
				title: 'Request status update',
				message: 'Are you sure want update payout status?',
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await togglePaidStatus({ id, user_id, amount, status });

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: 'Updating the status',
					success: 'Status has been changed',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ togglePaidStatus ]
	);

	if (!user) {
		if (empty) {
			return <Redirect to="/manage/users" />
		}
		return null;
	}

	const {
		amount,
		account: {
			email,
			first_name,
			last_name,
			user_type,
		},
	} = user;

	const RequestsList = requests?.requests.map((request, i) => {

		const { type, paid, status, order_number, payment } = request;
		const [ title, , icon ] = REQUEST_TYPE[type];

		if (!payment) {
			return null;
		}

		const {
			athlete_id,
			athlete_name,
			sender,
			amount,
			sender_name,
			payout_status
		} = payment;

		return (
			<div key={i} className="request-list--item" onClick={() => over.show('request', request)}>
				<SquareIcon icon={{ name: icon }} />
				<div className="request-item--content">
					<h4>{title} #{order_number} <span className={`request-status request-s${status}`}>{REQUEST_STATUS[status][0]}</span></h4>
					{user.account.user_type === 2 ? (
					<p>To <b>{athlete_name}</b> from <Link onClick={e => e.stopPropagation()} to={`/manage/user/${sender}`}>{sender_name}</Link></p>
					) : (
					<p>To <Link onClick={e => e.stopPropagation()} to={`/manage/user/${athlete_id}`}>{athlete_name}</Link> from <b>{sender_name}</b></p>
					)}
				</div>
				<div className="request-item--amount">${amount}</div>
				{(user.account.user_type === 2 && status === 5 && paid) && (
				<Button
					disabled={payout_status}
					onClick={payout_status ? undefined : (e) => {
						e.stopPropagation();
						_togglePaidStatus(request.id, id, request.payment?.amount || 0, true);
					}}
					variant={payout_status ? undefined : 'primary'}
					label={payout_status ? 'Paid' : `Set paid`} />
				)}
			</div>
		);

	});

	return (
		<>
			<div ref={wrapRef} className="manage-content with-side">
				<div className="page-header">
					<h1><SquareIcon onClick={goBack} icon="arrowBack" /> Requests {first_name && <span>{user_type === 2 ? `to ${first_name}` : `from ${first_name}`}</span>}</h1>
					<p className="amount">${amount}</p>
				</div>
				<div className="page-middle">
					<Select
						compact
						options={[
							{ label: 'Any type', value: -1 },
							...RequestTypeOptions
						]}
						onChange={({ value }) => {
							setQuery({
								page: undefined,
								requestType: value as string | undefined,
							}, 'replace');
						}}
						value={params.request_type}
						defaultValue={params.request_type} />
					<Select
						compact
						options={[
							{ label: 'Any status', value: -1 },
							{ label: 'Unpaid only', value: 0, },
							{ label: 'Paid only', value: 1, },
						]}
						onChange={({ value }) => {
							setQuery({
								page: undefined,
								payoutStatus: value as 0 | 1 | undefined,
							}, 'replace');
						}}
						value={params.payout_status}
						defaultValue={params.payout_status} />
					<Loader
						loading={loading.readRequests} />
				</div>
				<div
					className="page-body"
					children={RequestsList} />
				{requests && !!(requests.requests.length || params.page) && (
				<div className="page-footer">
					<Pages
						pageCount={requests.total_pages}
						forcePage={params.page}
						onPageChange={({ selected: page }) => {
							setQuery({ page: page === 0 ? undefined : page }, 'replace');
						}} />
				</div>
				)}
				{(requests && !requests.requests.length && !params.page) && (
				<div className="page-footer">
					<p className="page-empty">User has no requests yet</p>
				</div>
				)}
				<Over {...over}>
					<OverItem
						{...over}
						name="request"
						render={(request) => <ModalRequest request={request as IRequest} />} />
					<OverItem
						{...over}
						name="edit"
						render={(id) => {
							if (!id) {
								return;
							}
							return (
								<ModalUserEdit
									athleteId={id as string}
									onClose={() => over.hide('edit')}
									updated={[ isUpdated, setIsUpdated ]} />
							);
						}} />
				</Over>
			</div>
			<div className="page-aside">
				<div className="user-profile">
					<div
						style={{ backgroundImage: `url(${mediaLink(user.account.profile_photo)})` }}
						className="user-background">
						{!user.account.profile_photo && (
						<div className="user-icon">
							<Icon name="userBig" />
						</div>
						)}
						<div className="user-info">
							<UserLabel big role={user_type} />
							<h4>{first_name ? `${first_name} ${last_name}` : email}</h4>
							<h6>{first_name ? email : ''}</h6>
						</div>
						<div className={cn('user-overlay', { empty: !user.account.profile_photo })} />
					</div>
				</div>
				<div className="user-content">
					<Legend
						label="User info">
						<OptionView
							label="ID"
							value={user.account.id} />
						<OptionView
							label="Phone"
							phone
							value={user.account.phone || 'No Phone Number'} />
						{!isBusiness(user.account) &&
						<OptionView
							label="Gender"
							value={util.optionById('genders', user.account.gender)?.label || 'No Gender Yet'} />
						}
						{isAthlete(user.account) && (
							<OptionView
							label="Sport"
							value={getSportsByIds(user.account.athlete_info.sports).join(', ') || 'No Sport Yet'} />
						)}
						{isAthlete(user.account) && (
						<OptionView
							label="School"
							value={user.account.athlete_info.school_name || 'No School Yet'} />
						)}
					</Legend>
					{(isAthlete(user.account) && !!getActiveServices(user.account.athlete_info.athlete_services).length) && (
					<Legend
						label="Athlete services">
						{getActiveServices(user.account.athlete_info.athlete_services).map(({ label, amount, note }, i) => (
						<OptionView
							key={i}
							label={label}
							value={<><p>${amount}</p>{note && <p>{note}</p>}</>}
							className="service" />
						))}
					</Legend>
					)}
					<div className="user-actions">
					{isAthlete(user.account) && (
					<Button
						label="Edit"
						variant="primary"
						onClick={() => over.show('edit', user.account.id)} />
					)}
					<Button
						label={`${user.account.blocked ? 'Unb' : 'B'}lock`}
						onClick={(e) => toggleBlock(user.account.id, user.account.blocked, e)} />
					{config?.features?.adminProfileDelete &&
					<Button
						label="Remove"
						onClick={(e) => remove(user.account.id, e)} />
					}
					</div>
				</div>
			</div>
		</>
	);

}
