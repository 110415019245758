import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { lang } from '~/constants';

import { IAthlete, isBusiness, RequestForm, requestSocialPlatform } from '~/services';
import { AthleteListed, Button, Input, Legend, RSelect } from '~/components';
import { toFixed } from '~/utils';
import { useAuth } from '~/store';

import { athletesCount, AthletesPicker, athletesSummaryByForm, _isInArray, _pushOrRemove } from '../AthletesPicker';
import { PageTitle } from '../Page';

const PAYMENT_OPTIONS = {
	stripe: 'Stripe',
	wireCharge: 'Wire charge',
} as const

const PAYMENT_OPTIONS_OPTIONS = Object.entries(PAYMENT_OPTIONS).map(
	([ value, label ]) => ({ value, label }),
);

export const EndorsementCampaign: React.FC<RequestForm.Props> = (props) => {

	const { onSubmit, onUpdate, processes } = props;

	const { account } = useAuth();

	const { goBack } = useHistory();

	const {
		control,
		reset,
		watch,
		setValue,
		getValues,
		handleSubmit
	} = useForm<RequestForm.EndorsementCampaign>();

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const [ picker, setPicker ] = useState(true);

	const summary = useMemo(
		() => athletesSummaryByForm(watch('athlete_prices')),
		[ watch() ]
	);

	useEffect(() => {

		onUpdate && onUpdate({
			...getValues(),
			athlete_ids: athletes.map(({ id }) => id)
		});

		const sub = watch(
			(form) => onUpdate && onUpdate({
				...form,
				athlete_ids: athletes.map(({ id }) => id)
			})
		);

		return () => sub.unsubscribe();

	// eslint-disable-next-line
	}, [ athletes ]);

	const submit = (form: RequestForm.EndorsementCampaign) => {

		if (!athletes.length) {
			return;
		}

		onSubmit({
			...form,
			athlete_ids: athletes.map(({ id }) => id),
		}, () => {
			reset();
			setAthletes([]);
		});

	}

	const onAthleteClick = useCallback(
		(athlete: IAthlete, e: React.MouseEvent) => {

			e.preventDefault();

			e.stopPropagation();

			setAthletes((val) => {

				setValue(
					`athlete_prices.${athlete.id}`,
					_isInArray(val, athlete) ?
						0 :
						athlete.endorsement_rate
				);

				return _pushOrRemove(val, athlete);

			});

		},
		[ setValue ]
	);

	if (picker) {
		return (
			<AthletesPicker
				shown={picker}
				onHide={() => setPicker(false)}
				summary={summary}
				selected={athletes}
				onAthleteClick={onAthleteClick} />
		);
	}

	return (
		<>
			<PageTitle
				title="Endorsement Campaign"
				onBack={goBack} />
			<div className="form--inner">
				<p
					className="message"
					children={lang.CAMPAIGN_FORM_TEXT} />
				<Input
					name="endorsement_task"
					icon="info"
					style={{ minHeight: 100 }}
					rules={{ required: true, maxLength: 500 }}
					maxLength={500}
					control={control}
					textarea
					placeholder="Endorsement task" />
				<Input
					name="endorsement_link"
					icon="link"
					control={control}
					placeholder="Endorsement link" />
				<Legend
					label={lang.SERVICES_PRICING}>
					{!!athletes.length && athletes.map((athlete, i) => (
					<AthleteListed
						key={i}
						athlete={athlete}
						control={control}
						onRemove={onAthleteClick} />
					))}
					<div className="legend-extender">
						<Button
							variant="secondary"
							disabled={processes}
							label={`Add ${athletes.length > 0 ? 'more ' : ''}athletes`}
							onClick={() => setPicker(true)} />
					</div>
				</Legend>
				<Legend
					label={lang.REQUEST_INFO_LABEL}
					children={
						<div className="legend-section">
							<Input
								name="name"
								note={lang.REQUEST_NAME_NOTE}
								rules={{ required: true }}
								control={control}
								placeholder={lang.REQUEST_NAME_PLACEHOLDER} />
							<RSelect
								name="social_media_platform"
								note={lang.REQUEST_PLATFORM_NOTE}
								isMulti
								control={control}
								options={requestSocialPlatform}
								placeholder={lang.REQUEST_PLATFORM_PLACEHOLDER}
								defaultValue={null} />
						</div>
					} />
				{!!isBusiness(account) && account?.business_info?.trusted &&
				<Legend
					label="Payment options">
					<div className="legend-section">
						<RSelect
							icon="payment"
							name="as_trusted"
							rules={{ required: true }}
							control={control}
							options={PAYMENT_OPTIONS_OPTIONS}
							placeholder="Payment options"
							defaultValue={PAYMENT_OPTIONS_OPTIONS[0]} />
					</div>
				</Legend>
				}
				<Legend
					label="Total campaign price">
					<div className="app--summary">
						<p children={athletesCount(summary[0])} />
						<p children={`$${toFixed(summary[1])}`} />
					</div>
				</Legend>
				<Button
					variant="primary"
					disabled={!athletes.length}
					disabledDeep
					loading={processes}
					label="Checkout"
					onClick={handleSubmit(submit)} />
			</div>
		</>
	);

}
