import { ProtectedRouteProps } from '~/services';

import { Frame, FrameManage, FrameTenants } from '~/containers';
import { config, usernameRegex } from '~/constants';

import * as Routes from '~/routes';
import * as Settings from '~/routes/settings';
import * as RoutesManage from '~/routes/manage';
import * as RoutesTenants from '~/routes/tenants';

export const PAGE_SIGNIN = '/login';

export const routes: ProtectedRouteProps[] = [
	{
		path: '/login',
		roles: -1,
		component: Routes.Login,
	},
	{
		path: '/restore',
		roles: -1,
		component: Routes.Restore,
	},
	{
		path: '/signup',
		roles: -1,
		component: Routes.SignUp,
	},
	{
		path: '/signup',
		roles: [11, 12, 13],
		component: Routes.SignUpForm,
	},
	{
		path: '/dashboard',
		roles: [0, 1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Dashboard,
	},
	{
		path: '/locker-room',
		roles: [2],
		frame: Frame,
		hasMenu: true,
		component: Routes.LockerRoom,
	},
	{
		path: '/stats',
		roles: [3],
		frame: Frame,
		hasMenu: true,
		component: Routes.BusinessStats,
	},
	{
		path: '/requests',
		roles: [1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Requests,
	},
	{
		path: '/notifications',
		roles: [1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Notifications,
	},
	{
		path: '/profile',
		roles: [1, 2, 3],
		frame: Frame,
		hasMenu: true,
		component: Routes.Profile,
	},
	{
		path: '/settings/info',
		roles: [1, 2, 3],
		frame: Frame,
		component: Settings.Info,
	},
	{
		path: '/settings/links',
		roles: [2],
		frame: Frame,
		component: Settings.Links,
	},
	{
		path: '/settings/survey',
		roles: [2],
		frame: Frame,
		component: Settings.Survey,
	},
	{
		path: '/settings/services',
		roles: [2],
		frame: Frame,
		component: Settings.Services,
	},
	{
		path: '/request/:id',
		roles: [1, 2, 3],
		frame: Frame,
		component: Routes.Request,
	},
	{
		// path: `/@:id(${usernameRegex})`,
		path: `/@:id`,
		roles: [0, 1, 2, 3],
		frame: Frame,
		component: Routes.AthletePreview,
	},
	{
		// path: `/p/@:id(${usernameRegex})`,
		path: `/p/@:id`,
		roles: [0, 1, 2, 3],
		frame: Frame,
		component: Routes.Athlete,
	},
	{
		path: `/request-form/:id/:type(personal_appearance|speaking_engagement|training_session|endorsement|endorsement_campaign|video_chat|shoutout|camp)`,
		roles: [1, 3],
		frame: Frame,
		component: Routes.RequestForm,
	},
	{
		path: '/chat/:id',
		roles: [1, 2, 3],
		frame: Frame,
		component: Routes.Chat,
	},
	{
		path: '/manage/users',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Users,
	},
	{
		path: '/manage/user/:id',
		exact: true,
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.User,
	},
	{
		path: '/manage/request/:id',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Request,
	},
	{
		path: '/manage/stats',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Stats,
	},
	{
		path: '/manage/payouts',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Payouts,
	},
	{
		path: '/manage/pushes',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Pushes,
	},
	{
		path: '/manage/sent-payouts',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.SentPayouts,
	},
	{
		path: '/manage/promo-codes',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.PromoCodes,
	},
	{
		path: '/manage/analytics',
		frame: FrameManage,
		roles: [4],
		component: RoutesManage.Analytics,
	},
];

if (config.CONFIG.features?.tenantsPage) {
	routes.push({
		path: '/tenants',
		exact: true,
		frame: FrameManage,
		roles: [4],
		component: RoutesTenants.Tenants,
	});
}
