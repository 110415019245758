export const lang = {
	INCORRECT_CREDENTIALS: 'Incorrect credentials',
	EMAIL_ALREADY_TAKEN: 'Email is taken already',
	USERNAME_ALREADY_TAKEN: 'Username is taken already',
	USERNAME_INVALID: 'Username should be alphanumeric with dots or underscores and be between 3 and 36 symbols',
	INVALID_PHONE: 'Phone number is incorrect',
	PASSWORD_RESTORE: 'Don\'t worry! Just in your email and we’ll send you a link to reset your password',
	PASSWORD_RESTORE_CONFIRM: 'Enter the 6 digit code sent to your email address and create a new password',
	PASSWORD_RESTORED_LABEL: 'Password restored',
	PASSWORD_RESTORED_MESSAGE: 'Now you can login with your new password',
	PASSWORD_INCORRECT_CODE: 'You have entered an incorrect reset code',
	PROFILE_EDIT_INFO_TITLE: 'Setup Profile',
	INCORRECT_DATE_IN_PAST: 'Time travel is not supported yet',
	GUEST_AUTH_PROMPT: 'In order to purchase a shoutout, we’ll need you to sign into your account!',
	STRIPE_PAYMENT_PROMPT: 'In order to purchase a shoutout, we’ll continue the payment using stripe.',
	REQUEST_SHOUTOUT_TITLE: `People who are receiving this shout out.\n You can add 1 or more people on this shoutout.`,
	REQUEST_SHOUTOUT_EMAIL_PROMPT: 'What email do you want to receive the video to?',
	REQUEST_SHOUTOUT_MESSAGE: (name: string) => `You are buying shoutout from ${name} for`,
	REQUEST_ENDORSEMENT_TITLE: `Request Endorsement`,
	REQUEST_ENDORSEMENT_MESSAGE: (name: string) => `You are buying endorsement from ${name} for`,
	REQUEST_PERSONAL_APPEARANCE_MESSAGE: (name: string) => `You are buying personal appearance from ${name} for`,
	REQUEST_VIDEO_CHAT_MESSAGE: (name: string) => `You are buying video chat from ${name} for`,
	FAN_SURVEY_CONFENERCE: 'Which of the following conferences and divisions do you follow?',
	FAN_SURVEY_SPORT: 'Which of the following Sports do you follow?',
	FAN_SURVEY_TEAM: 'Which of the following teams do you follow?',
	BUSINESS_TITLE: 'Sign up as a Business with Facebook',
	BUSINESS_BUTTON: 'Connect your business account using facebook',
	BUSINESS_BUTTON2: 'Sign In your business account using facebook',
	INCORRECT_BUSINESS: 'You trying to link regular Facebook account, use your Facebook company account',
	CAMPAIGN_MODAL_TEXT: 'Create a campaign by selecting various amount of athletes to make an outstanding endorsement for your business! Keep in mind you will still have to review each endorsement separately',
	CAMPAIGN_FORM_TEXT: 'Request endorsement campaign',
	PHOTO_EMPTINESS: 'Please, choose photo',
	VIDEO_TYPE_LIMIT: (types: string[]) => `Video should be one of the following types: ${types.join(', ')}`,
	IMAGE_TYPE_LIMIT: (types: string[]) => `Image should be one of the following types: ${types.join(', ')}`,
	VIDEO_SIZE_LIMIT: (weight: number) => `Video should not weight more than ${weight} mb`,
	VIDEO_DURATION_LIMIT: (length: number) => `Video should not be more than ${length} seconds`,
	VIDEO_EMPTINESS: 'Please, choose video',
	SIGNUP_VIDEO_TITLE: 'Upload your profile video',
	SIGNUP_DESCRIPTION: 'Write a little about yourself',
	SIGNUP_SURVEY_SPORT_TITLE: 'Which sport do you play?',
	SIGNUP_SURVEY_SPORT_KICKER: 'You can choose more than 1 sport',
	SIGNUP_SURVEY_SCHOOL_TITLE: 'Which school do you attend?',
	SIGNUP_SURVEY_SCHOOL_KICKER: 'You can choose no more than 1 school',
	SIGNUP_SURVEY_SCHOOL_SEARCH: 'You can search for your school',
	SIGNUP_SURVEY_SOCIALS_TITLE: 'Optional: type links to your social networks or do it later',
	NOT_FOUND_ATHLETE_TITLE: 'Athlete not found',
	NOT_FOUND_ATHLETE_MESSAGE: 'We could not find this athlete',
	NOT_FOUND_REQUEST_TITLE: 'Request not found',
	NOT_FOUND_REQUEST_MESSAGE: 'We could not find this request',
	SUPPORT_CONTACT_MESSAGE: 'Please contact customer support and provide details about your issue:',
	TRAVEL_PARAMS: 'Travel Parameters',
	TRAVEL_PARAMS_ADD: (more: boolean) => `Add${more ? ' more' : ''} locations`,
	SERVICES_SAVE: 'Save services',
	SERVICES_LABEL: 'Services offered',
	SERVICES_REQUIRE: 'You have to choose at least one type of service',
	SOCIALS_LABEL: 'Social links',
	SOCIALS_SAVE: 'Save social links',
	GENERAL_INFO_LABEL: 'General info',
	GENERAL_INFO_SAVE: 'Save account',
	GENERAL_INFO_MEDIA_LABEL: 'Update video',
	GENERAL_INFO_SURVEY_LABEL: 'Sport & school',
	SELECT_SCHOOL: 'Select school',
	SELECT_SCHOOL_SEARCH: 'Start typing to find the school you need',
	SELECT_SPORT: 'Select sport',
	SERVICES_PRICING: 'Services Pricing',
	NO_REVIEWS: (name: string) => `${name} has no reviews yet`,
	NO_HISTORY: (name: string) => `${name} has no history yet`,

	REQUEST_INFO_LABEL: 'Request Info',
	REQUEST_NAME_LABEL: 'Request Name',
	REQUEST_PLATFORM_LABEL: 'Request Social Platform',

	REQUEST_NAME_PLACEHOLDER: 'Request name',
	REQUEST_NAME_NOTE: 'Enter a name to make it easier to identify requests later',
	REQUEST_PLATFORM_PLACEHOLDER: 'Request social platform',
	REQUEST_PLATFORM_NOTE: 'If this request include a social media element please select the platform associated',

	ENDORSEMENT_PRICE_LABEL: 'Total price',
	ENDORSEMENT_PRICE_PLACEHOLDER: 'Price amount',
	ENDORSEMENT_PRICE_ERROR_REQUIRED: 'This field is required',
	ENDORSEMENT_PRICE_ERROR_INVALID: 'Please, enter valid amount',

	LEGENDARY_NOTE: 'Alumni or no longer a college athlete',
	LEGENDARY_LABEL: 'Legendary athlete',
	LEGENDARY_CHECK: 'Show Legendary Athletes',
	FEATURED_LABEL: 'Featured',
} as const;
