import { useMemo } from 'react';
import { parseMediaLink, RequestViewComponent as RC } from '~/services';
import { Legend } from '~/components';

import { normalizeURL } from '~/utils';
import { RequestEarnings, RequestPerson, RequestInfo, RequestAssets } from '../Request';

export const Endorsement: RC<'endorsement'> = (props) => {

	const { request: { profile, ...request }, account } = props;

	const {
		status,
		endorsement,
	} = request;

	const media = useMemo(
		() => parseMediaLink(endorsement.video),
		[ endorsement ],
	);

	return (
		<>
			{!!(account.user_type !== 2 && status >= 4) && ('assets' in request.endorsement) && (
			<RequestAssets
				type={request.type}
				assets={[]}
				asView={request.endorsement.assets} />
			)}
			<RequestInfo
				request={request} />
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Endorsement information"
				children={endorsement.task} />
			{!!endorsement.link && (
			<Legend
				label="Endorsement link"
				children={(
					!!normalizeURL(endorsement.link) ? (
					<a
						rel="noreferrer"
						href={normalizeURL(endorsement.link)}
						target="_blank"
						children={endorsement.link}
						className="link" />
					) : (
					<p>{endorsement.link}</p>
					)
				)} />
			)}
			<RequestEarnings
				request={request}
				profile={profile} />
		</>
	);

}
