import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { lang } from '~/constants';
import { useAuth } from '~/store';
import { isBusiness, useAthleteSearch } from '~/services';
import { AthleteCard, Button, Check, Icon, Search, Select, Skeleton, Tooltip } from '~/components';
import { PageContent, PageTitle } from '~/containers';

import { CampaignPrompt } from '../containers/Modals';

export const Dashboard: React.FC = () => {

	const { account } = useAuth();

	const { athletes, loading, filter, field, legend, text, trigger, hasFeatured } = useAthleteSearch();

	const { push } = useHistory();

	const [ campaignPrompt, setCampaignPrompt ] = useState<boolean>(false);

	const SearchAddon = (
		<Select
			value={field}
			options={text.SEARCH_FIELDS_OPTIONS}
			onChange={({ value }) => trigger('field', value as string)} />
	);

	const PageHeader = (
		<div className="dashboard-header">
			<div className={cn('content-search', { business: !!isBusiness(account) })}>
				<Search
					loading={loading === 2}
					onReset={trigger}
					onSearch={(value) => trigger('filter', value)}
					placeholder={text.SEARCH_PLACEHOLDER[(field || 'name')]}
					searchAddon={SearchAddon}
					defaultValue={filter} />
				{isBusiness(account) && (
				<>
					<Button
						label="Create campaign"
						variant="primary"
						onClick={() => setCampaignPrompt(true)} />
				</>
				)}
			</div>
			<div className="content-legends">
				<div className="legends-check">
					<Check
						label={!!loading ? 'Loading...' : lang.LEGENDARY_CHECK}
						checked={!!legend}
						onClick={() => trigger('legend', legend ? undefined : '1')} />
					<Tooltip
						text={lang.LEGENDARY_NOTE} />
				</div>
			</div>
			{!!hasFeatured &&
			<div className="content-featured">
				<p>{lang.FEATURED_LABEL}</p>
			</div>
			}
		</div>
	);

	const EmptyContent = (
		<p className="content--empty">Athletes not found</p>
	);

	return (
		<>
			<PageTitle title="Dashboard" />
			<PageContent
				data={athletes}
				loading={loading === 1}
				loadingComponent={<Skeleton />}
				renderItem={(athlete) => (
					<AthleteCard
						key={athlete.id}
						athlete={athlete}
						onClick={() => push(`/@${athlete.username}`)} />
				)}
				pageContentHeader={PageHeader}
				pageContentClassName={cn('grid-3s', { 'has-featured': hasFeatured })}
				pageEmptyComponent={EmptyContent}>
			</PageContent>
			<CampaignPrompt
				isVisible={campaignPrompt}
				setVisile={setCampaignPrompt} />
		</>
	);

}
