import { api } from '../api';

export namespace DataType {

	export type Conference = {
		id: string,
		name: string,
		sport_id: string
	}

	export type Gender = {
		id: string,
		name: string,
	}

	export type School = {
		id: string,
		name: string,
	}

	export type Sport = {
		id: string,
		name: string
	}

	export type Team = {
		id: string,
		name: string,
		university: string,
		conference_id: string
	}

	export type Union =
		Conference |
		Gender |
		School |
		Sport |
		Team

}

export type RefDataResponse = {
	iap_apple_products: string[],
	available_schools: DataType.School[],
	stripe_public: string,
	grafana_url?: string,
	intro_video: string,
	genders: DataType.Gender[],
	schools: DataType.School[],
	sports: DataType.Sport[]
}

export const Data = {

	read: () => api.get<RefDataResponse>('/public/meta/reference-data'),

};
