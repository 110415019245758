import { ChangeEventHandler, useCallback, useState } from 'react';
import mime from 'mime';

import { useData } from '~/store';
import { config, lang } from '~/constants';
import { getFileType, getVideoDuration } from '~/utils';
import { Button, ButtonProps, PhotoWrap } from '~/components';

import { Legend } from './Legend';
import { VideoWrap } from './VideoWrap';
import { ButtonInput, ButtonInputProps } from './ButtonInput';
import { MediaType } from '~/containers/Request';

interface VideoButtonProps {
	label?: string,
	video?: string | File,
	error?: string,
	note?: string,
	accept?: ButtonInputProps['accept'],
	variant?: ButtonProps['variant'],
	multiple?: boolean,
	forceLabel?: string,
	isLoading?: boolean,
	noLegend?: boolean,
	limitType?: string[],
	limitSize?: number,
	limitDuration?: number,
	limitImageType?: string[],
	usePhoto?: boolean,
	useDefault?: boolean,
	hideDefault?: boolean,
	onError?: (error: string) => void,
	onVideoChange?: (file: string | File) => void,
	onFilesChange?: (files: File[]) => void,
}

const isVideoDefault = (path?: string | File) => {
	if (!path || typeof path !== 'string') {
		return false;
	}
	return /^default$|default.mp4$/i.test(path);
}

export const VideoButton: React.FC<VideoButtonProps> = (props) => {

	const {
		label = 'Upload your video',
		video,
		error,
		note,
		accept,
		variant = 'secondary',
		multiple,
		forceLabel,
		isLoading,
		noLegend,
		limitType = config.VIDEO_TYPE_LIMIT,
		limitSize = config.VIDEO_SIZE_LIMIT,
		limitDuration = config.VIDEO_DURATION_LIMIT,
		limitImageType = config.IMAGE_TYPE_LIMIT,
		usePhoto,
		useDefault,
		hideDefault,
		onError,
		onVideoChange,
		onFilesChange,
	} = props;

	const [ loading, setLoading ] = useState(false);

	const { intro_video } = useData();

	const onUseDefault = useCallback(
		() => {

			onVideoChange && onVideoChange('default');

		},
		[ onVideoChange ]
	);

	const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		async ({ target }) => {

			if (!target.files || !target.files[0]) {
				return;
			}

			async function handler (file: File): Promise<File | string> {

				const [ type, extension ] = getFileType(file.type);
				const size = file.size / 1e+6;

				switch (type) {
					case 'video': {

						if (extension && !limitType.includes(extension)) {
							return lang.VIDEO_TYPE_LIMIT(limitType);
						}

						if (size > limitSize) {
							return lang.VIDEO_SIZE_LIMIT(limitSize);
						}

						const duration = await getVideoDuration(file, true);

						setLoading(false);

						if (duration > limitDuration) {
							return lang.VIDEO_DURATION_LIMIT(limitDuration);
						}

						return file;

					}
					case 'image': {

						if (extension && !limitImageType.includes(extension)) {
							return lang.IMAGE_TYPE_LIMIT(limitType);
						}

						return file;

					}
					default: {
						return 'Unknown file type';
					}
				}

			}

			if (multiple) {

				setLoading(true);

				const filesStore: File[] = [];
				const filesArray = Array.from(target.files);

				for (const file of filesArray) {
					const err = await handler(file);
					if (typeof err === 'string') {
						onError?.(err);
						break;
					} else {
						filesStore.push(file);
					}
				}

				onFilesChange?.(filesStore);

				setLoading(false);

				return;

			}

			const file = target.files[0];
			const type = file.type.split('/', 2).pop() || '';
			const size = file.size / 1e+6;

			if (usePhoto) {

				if (type && !limitImageType.includes(type)) {
					return onError && onError(lang.IMAGE_TYPE_LIMIT(limitType));
				}

				onVideoChange && onVideoChange(file);

				return;

			}

			if (type && !limitType.includes(type)) {
				return onError && onError(lang.VIDEO_TYPE_LIMIT(limitType));
			}

			if (size > limitSize) {
				return onError && onError(lang.VIDEO_SIZE_LIMIT(limitSize));
			}

			setLoading(true);

			const duration = await getVideoDuration(file, true);

			setLoading(false);

			if (duration > limitDuration) {
				return onError && onError(lang.VIDEO_DURATION_LIMIT(limitDuration));
			}

			onVideoChange && onVideoChange(file);

		},
		[
			onError,
			onVideoChange,
			onFilesChange,
			multiple,
			usePhoto,
			limitType,
			limitSize,
			limitDuration,
		]
	);

	const content = (
		<>
			{!!(video && !usePhoto) && (
			<>
				{!(hideDefault && !isVideoDefault(video)) &&
				<VideoWrap
					source={{ uri: isVideoDefault(video) ? intro_video : video }} />
				}
			</>
			)}
			{!!(video && usePhoto) && (
			<PhotoWrap
				source={video} />
			)}
			{(useDefault && !hideDefault) &&
			<Button
				variant="primary"
				onClick={onUseDefault}
				style={{ marginBottom: 20 }}
				label="Upload playbooked video"
				type="button" />
			}
			{!!note &&
			<p className="video__note">{note}</p>
			}
			<ButtonInput
				label={forceLabel || `${video && !isVideoDefault(video) ? 'Replace' : 'Upload'} ${useDefault ? ' personal ' : ''}${usePhoto ? 'photo' : 'video'}`}
				image={usePhoto}
				accept={accept}
				variant={variant}
				loading={isLoading || loading}
				multiple={multiple}
				onChange={onChange} />
			{!!error && <p className="error" children={error} />}
		</>
	);

	if (noLegend) {
		return content;
	}

	return (
		<Legend
			label={label}
			error={error}
			children={content}
			className="video--legend" />
	);

}
