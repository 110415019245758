import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAuth, useConfig } from '~/store';
import { Button, Icon, Logo } from '~/components';

export const FrameManage: React.FC = (props) => {

	const { children } = props;

	const { loading, unAuthorize } = useAuth();

	const config = useConfig();

	return (
		<>
			<aside className="manage-aside">
				<Link to="/manage/users">
					<Logo />
				</Link>
				<nav>
					<NavLink to="/manage/users">
						<Icon name="MenuProfile" />
						<span>Users</span>
					</NavLink>
					{!!config?.features?.pushes &&
					<NavLink to="/manage/pushes">
						<Icon name="MenuRequests" />
						<span>Pushes</span>
					</NavLink>
					}
					{!!config?.features?.promoCodes &&
					<NavLink to="/manage/promo-codes">
						<Icon name="MenuLockerRoom" />
						<span>Promo Codes</span>
					</NavLink>
					}
					<NavLink to="/manage/analytics">
						<Icon name="menuAnalytics" />
						<span>Analytics</span>
					</NavLink>
					{!!config?.features?.tenantsPage &&
					<>
					<NavLink to="/manage/payouts">
						<Icon name="MenuDashboard" />
						<span>Payouts</span>
					</NavLink>
					<NavLink to="/tenants">
						<Icon name="menuTenants" />
						<span>Tenants</span>
					</NavLink>
					</>
					}
				</nav>
				<div className="logout">
					<Button
						label="Logout"
						loading={loading.unAuthorize}
						onClick={() => unAuthorize()} />
				</div>
			</aside>
			<main children={children} />
			<ToastContainer
				theme="dark"
				position="bottom-right" />
		</>
	);

}
