import React, { useCallback } from 'react';

import { handleError, RequestAsset, RequestType } from '~/services';
import { AssetView, Grid, Legend, VideoButton } from '~/components';
import { Asset, getFileType } from '~/utils';
import { config } from '~/constants';
import FileSaver from 'file-saver';

export type MediaType = 'image' | 'video'

export interface RequestAssetsProps {
	type: Extract<RequestType, 'endorsement' | 'shoutout'>,
	assets: File[],
	asView?: RequestAsset[],
	isLoading?: boolean,
	onAssetsChange?: React.Dispatch<React.SetStateAction<File[]>>,
}

export const normalizeRequestAsset = (asset: RequestAsset): Asset => {
	return asset.url;
}

export const RequestAssets: React.FC<RequestAssetsProps> = (props) => {

	const {
		type,
		assets,
		asView,
		isLoading,
		onAssetsChange,
	} = props;

	const onAssetDelete = useCallback(
		(asset: File) => {
			onAssetsChange?.(
				(val) => val.filter(({ name }) => name !== asset.name)
			);
		},
		[ onAssetsChange ]
	);

	const onAssetDownload = useCallback(
		(asset: string) => {

			try {

				const [ fileType, extension ] = getFileType(asset);

				const url = config.URL_BASE + asset;

				FileSaver.saveAs(url, `${type}-${fileType}.${extension}`);

			} catch (e) {

				handleError(e);

			}

		},
		[ type ]
	);

	const onFilesChange = useCallback(
		(files: File[]) => {
			onAssetsChange?.((val) => {

				const comparer = (i: File) => `${i.name}-${i.size}`;

				const fileKeys = val.map(comparer);
				const newFiles = files.filter((i) => !fileKeys.includes(comparer(i)));

				return [ ...val, ...newFiles ];

			});
		},
		[]
	);

	if (asView) {
		return (
			<Legend
				label={`${type} Files`}>
				<Grid
					content={asView}
					rowCount={5}
					children={(asset, i) => (
						<AssetView
							key={i}
							asset={normalizeRequestAsset(asset)}
							onAssetDownload={onAssetDownload} />
					)} />
			</Legend>
		);
	}

	return (
		<Legend
			label={`${type} Files`}
			hasPadding>
			{assets.length &&
			<Grid
				content={assets}
				rowCount={5}
				extraData={isLoading}
				children={(asset, i) => (
					<AssetView
						key={i}
						asset={asset}
						isLoading={isLoading}
						onAssetDelete={onAssetDelete} />
				)} />
			}
			<VideoButton
				accept="all"
				multiple
				noLegend
				isLoading={isLoading}
				forceLabel={`Add ${!assets.length ? `${type} file` : `more ${type} files`}`}
				limitDuration={99999}
				onFilesChange={onFilesChange} />
		</Legend>
	);

}
