import { Controller } from 'react-hook-form';
import Select, { components, Props, MenuListProps, GroupBase, StylesConfig, ThemeConfig } from 'react-select';
import cn from 'classnames';

import colors from '~/assets/scss/vars.module.scss';
import { ControlledField, getState } from '../types';
import { IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';

interface RSelectProps extends Props {
	note?: string,
	icon?: IconArgs | null
}

const MenuList: React.FC<MenuListProps<unknown, boolean, GroupBase<unknown>>> = ({ children, ...props }) => {

	return (
		<components.MenuList {...props}>
			{Array.isArray(children)
				? children.slice(0, 50)
				: children
			}
		</components.MenuList>
	);

}

const theme: ThemeConfig = (theme) => ({
	...theme,
	borderRadius: Number(colors.borderRadius.slice(0, -2)),
	spacing: {
		...theme.spacing,
		baseUnit: 7,
	},
	colors: {
		...theme.colors,
		text: 'black',
		primary: 'black'
	},
});

const styles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	input: (provided) => ({
		...provided,
		color: colors.colorTextWhite,
		font: `400 14px ${colors.fontFamily}`,
	}),
	singleValue: (provided) => ({
		...provided,
		color: colors.colorTextWhite,
	}),
	control: (provided) => ({
		...provided,
		backgroundColor: colors.inputBackgroundColor,
		boxShadow: colors.inputBoxShadow,
		border: colors.inputBorder,
		cursor: 'pointer',
		':hover': {
			border: colors.inputBorder,
		},
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99,
		boxShadow: colors.inputBoxShadow,
		border: `1px solid ${colors.backgroundPrimary20}`,
		backgroundColor: colors.backgroundPrimary10,
	}),
	option: (provided) => ({
		...provided,
		cursor: 'pointer',
		backgroundColor: colors.backgroundPrimary10,
		':hover': {
			backgroundColor: colors.backgroundPrimary20,
		},
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: colors.colorTextWhite,
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: colors.backgroundPrimary10,
	}),
};

type CProps = ControlledField<RSelectProps>

export const RSelectInner: React.FC<CProps['inner']> = (props) => {

	const { icon, note, ...args } = props;

	const state = getState(args);

	return (
		<div className={cn('app--r-select', { invalid: !!state?.error })}>
			{icon && (
			<SquareIcon type="button" icon={icon} />
			)}
			<div className="app--r-select-container">
				<Select
					{...args}
					theme={theme}
					styles={styles}
					components={{
						MenuList,
						IndicatorSeparator: () => null }} />
				{note && <p className="note">{note}</p>}
			</div>
		</div>
	);

}

export const RSelect: React.FC<CProps['outer']> = (props) => {

	if (!('control' in props)) {
		return <RSelectInner {...props} />
	}

	const {
		name = '',
		rules = {},
		control,
		defaultValue = '',
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => {

				const { value, onBlur, onChange } = field;

				return (
					<RSelectInner
						{...props}
						value={value}
						state={fieldState}
						onBlur={onBlur}
						onChange={onChange} />
				);

			}} />
	);

}
