import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useEscapeKeydown } from '@radix-ui/react-use-escape-keydown';

import { Arrayable } from '~/types';
import { asArray, fromArray } from '~/utils';

import { Title } from './Title';
import { Icon, IconArgs, asIcon } from './Icon';
import { SquareIcon } from './SquareIcon';

export interface ModalProps {
	title: Arrayable<string>,
	icon?: Arrayable<IconArgs>,
	layer?: number,
	visible?: boolean,
	lockHide?: boolean,
	noPadding?: boolean,
	className?: Arrayable<string>,
	isLoading?: boolean,
	onHide?: () => void,
	setLayer?: (layer?: number) => void,
}

export const Modal: React.FC<ModalProps> = (props) => {

	const {
		icon,
		title,
		layer,
		visible,
		children,
		className,
		isLoading,
		onHide
	} = props;

	useEscapeKeydown(() => {

		if (!visible) {
			return;
		}

		onHide && onHide();

	});

	useEffect(() => {

		document.body.style.overflow = visible ? 'hidden' : 'auto';

		return () => {
			document.body.style.overflow = 'auto';
		}

	}, [ visible ]);

	return createPortal(
		<div className={cn('app-modal', asArray(className)[layer || 0], { visible })}>
			<div className="app-modal--wrap">
				<Title label={asArray(title)[layer || 0]} />
				<div className="app-modal--content">
					{(icon || onHide) && (
					<div className="app-modal--header">
						{!!onHide && (
							<SquareIcon
								icon="cross"
								onClick={onHide} />
						)}
						{!!icon && <Icon {...asIcon(asArray(icon)[layer || 0])} />}
					</div>
					)}
					<div children={fromArray(children, layer)} />
				</div>
			</div>
			<div
				className="app-modal--bg"
				onClick={onHide} />
		</div>,
		document.querySelector('#over')!
	);

}
